<template>
  <div class="max-w-scrollable-section mx-auto gap-4 py-10 lg:max-w-7xl">
    <div class="flex flex-col gap-7">
      <h2 class="text-grays-darkest flex-1 text-2xl font-semibold leading-9">
        {{ title }}
      </h2>
      <div
        class="flex gap-7 overflow-x-auto pb-4 pr-6 lg:grid lg:grid-cols-4 lg:overflow-hidden lg:pb-0 lg:pr-0 items-end"
      >
        <plp-product-list-item
          v-for="product in products"
          :key="product.sku"
          :product="product"
          :show-try-on-button="
            tryOnAvailableFrames?.includes(formatSkuId(product?.sku)) ?? false
          "
          :available-credit="availableCredit"
          :active-image="activeImage"
          @try-on-clicked="() => handleTryOnClick(product)"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineComponent, useEmployeeGtmTrackEvent, useNuxtApp } from '#imports'
import { formatSkuId } from '~/utils/skuIdGetters'
defineComponent({
  name: 'ProductListSection',
})
const { pdpClickTryOnTrackEvent } = useEmployeeGtmTrackEvent()
const { $fittingboxContext } = useNuxtApp()
defineProps({
  tryOnAvailableFrames: {
    type: Array<string>,
    required: true,
    default: [],
  },
  products: {
    type: Array<any>,
    required: true,
    default: [],
  },
  title: {
    type: String,
    required: true,
    default: '',
  },
  availableCredit: {
    type: Number,
    required: false,
    default: 0,
  },
  activeImage: {
    type: String,
    required: false,
    default: undefined,
  },
})

const handleTryOnClick = (product?: any) => {
  if (product?.sku) {
    pdpClickTryOnTrackEvent(product)
    $fittingboxContext.selectFrame(formatSkuId(product?.sku))
  }
}
</script>
